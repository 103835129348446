/* stylelint-disable selector-max-id */
/* stylelint-disable declaration-no-important */

/*
    Veralteter-Browser-Modal
 */
#old-browser-modal {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.95);

  #old-browser-modal-text {
    display: inline-block;
    position: relative;
    top: 50%;
    left: 50%;
    padding: 30px 20px;

    background: #fff;
    border-radius: 15px;

    transform: translate(-50%, -50%);

    p {
      -ms-word-wrap: break-word;
      word-break: break-all;
    }
  }
}

@supports not ( display: grid ) {
  #old-browser-modal {
    display: block !important;
  }
}
@media screen\0 {
  #old-browser-modal {
    display: block !important;
  }
}
