/* stylelint-disable */

/*
  Cookiebot
 */
a#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll {
  color: #949494 !important;
}

#CybotCookiebotDialog a, #CybotCookiebotDialog div, #CybotCookiebotDialogBodyContentControls, #CybotCookiebotDialogBodyContentTitle {
  font-family: Ubuntu, sans-serif !important;
}
