// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

html {
  overflow-x: hidden;

  scroll-behavior: smooth;

  &.no-scroll {
    overflow: hidden !important; /* stylelint-disable-line declaration-no-important */
  }
}

body {
  overflow-x: hidden;

  background-color: #fff;

  font-family: Ubuntu, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
