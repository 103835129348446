/*
    Standard Elemente
 */
.container {
  width: 100%;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 1100px) {
    width: 90%;
  }
}

.color-nettrek {
  color: #199cd7;
}

.color-e5 {
  color: #f16529;
}

.color-vr {
  color: #d7199c;
}

.color-quiz {
  color: #1ebdaf;
}

.color-lcms {
  color: #6380d6;
}

.round-button {
  padding: 10px 30px;

  border: 0;
  border-radius: 27px;
  outline: none;

  color: #fff;
  font-size: 17px;
  text-align: center;

  /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
  transition: all 0.25s;

  &.color-e5 {
    background: #f16529;
  }

  &.color-vr {
    background: #d7199c;
  }

  &.color-quiz {
    background: #1ebdaf;
  }

  &.color-lcms {
    background: #6380d6;
  }

  &.color-nettrek {
    background: #fff;

    color: #094560;
  }

  &.color-nettrek-light {
    background: #199cd7;

    color: #fff;
    font-size: 18px;

    &:hover {
      background: #094560;
    }
  }

  &.inverted {
    padding: 10px 15px;

    background: transparent;

    font-weight: 300;

    &.color-e5 {
      border: 1px solid #f16529;

      color: #f16529;
    }

    &.color-vr {
      border: 1px solid #d7199c;

      color: #d7199c;
    }

    &.color-quiz {
      border: 1px solid #1ebdaf;

      color: #1ebdaf;
    }

    &.color-lcms {
      border: 1px solid #6380d6;

      color: #6380d6;
    }

    &.color-nettrek {
      border: 1px solid #fff;

      color: #fff;
    }
  }

  svg {
    margin-left: 10px;
  }
}

// for quill editor in ed-admin
/* stylelint-disable selector-class-pattern */
.ql-buttonLink {
  display: block;
  padding: 10px 30px;

  background: #199cd7;
  border: 0;
  border-radius: 27px;
  outline: 0;

  color: #fff;
  font-size: 17px;
  text-align: center;

  /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
  transition: all 0.25s;

  &:hover {
    background: #094560;

    text-decoration: underline;
  }
}
/* stylelint-enable selector-class-pattern */

button { // disable double tap zooming on safari
  touch-action: manipulation;
}

@media(max-width: 599px) {
  .cookieconsent-optin-marketing {
    iframe {
      /* stylelint-disable-next-line declaration-no-important */
      height: 250px !important;
    }
  }
}
