// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

/* ubuntu-300 - latin */
@font-face {
  font-family: 'Ubuntu';
  src: url('../../assets/fonts/ubuntu/ubuntu-v13-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Light'), local('Ubuntu-Light'),
    url('../../assets/fonts/ubuntu/ubuntu-v13-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../assets/fonts/ubuntu/ubuntu-v13-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../assets/fonts/ubuntu/ubuntu-v13-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../../assets/fonts/ubuntu/ubuntu-v13-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../assets/fonts/ubuntu/ubuntu-v13-latin-300.svg#Ubuntu') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

/* ubuntu-regular - latin */
@font-face {
  font-family: 'Ubuntu';
  src: url('../../assets/fonts/ubuntu/ubuntu-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
    url('../../assets/fonts/ubuntu/ubuntu-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../assets/fonts/ubuntu/ubuntu-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../assets/fonts/ubuntu/ubuntu-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../../assets/fonts/ubuntu/ubuntu-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../assets/fonts/ubuntu/ubuntu-v13-latin-regular.svg#Ubuntu') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

/* ubuntu-500 - latin */
@font-face {
  font-family: 'Ubuntu';
  src: url('../../assets/fonts/ubuntu/ubuntu-v13-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Medium'), local('Ubuntu-Medium'),
    url('../../assets/fonts/ubuntu/ubuntu-v13-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../../assets/fonts/ubuntu/ubuntu-v13-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../../assets/fonts/ubuntu/ubuntu-v13-latin-500.woff') format('woff'), /* Modern Browsers */
    url('../../assets/fonts/ubuntu/ubuntu-v13-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../../assets/fonts/ubuntu/ubuntu-v13-latin-500.svg#Ubuntu') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
