/* stylelint-disable selector-max-compound-selectors */

// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// On event hover active focus
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @content Inside pseudo classes
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @param {String} $context
/// @content Inside $context <current-class>
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

@mixin common-news-detail-and-projekte-detail {
  width: 100%;
  padding: 40px 0;

  color: #094560;

  ::ng-deep hr {
    max-width: calc(740px - 15%);
    height: 1px;
    margin-top: 40px;
    margin-bottom: 40px;

    background: #cbdae1;
    border: 0;
  }

  ::ng-deep figure,
  ::ng-deep img {
    max-width: 740px;
    margin: 20px auto;
  }

  ::ng-deep figure.kg-width-full {
    max-width: 1000px;
  }

  ::ng-deep figure {
    text-align: center;

    ::ng-deep img {
      max-width: 100%;

      border-radius: 15px;
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.27);

      vertical-align: middle;
    }

    ::ng-deep figcaption {
      margin-top: 10px;

      color: #094560;
      font-size: small;
    }
  }

  ::ng-deep p {
    color: #094560;

    &:first-of-type {
      margin-top: 0;
    }
  }

  ::ng-deep strong,
  ::ng-deep b {
    font-weight: 700;
  }

  ::ng-deep li {
    margin: 15px auto;

    font-size: 17px;
    font-weight: 300;
  }

  ::ng-deep blockquote {
    box-sizing: border-box;
    width: 100%;
    margin: 30px auto;
    padding: 30px;

    border-radius: 15px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);

    font-size: 22px;
    font-weight: 300;
    line-height: 32px;

    > em {
      /* stylelint-disable-next-line declaration-no-important */
      font-style: normal !important;
    }
  }

  ::ng-deep .kg-gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    max-width: 100%;
    margin-bottom: 20px;
  }

  ::ng-deep .kg-gallery-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1000px;
  }

  ::ng-deep .kg-gallery-image img {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;

    object-fit: cover;
  }

  ::ng-deep .kg-gallery-row:not(:first-of-type) {
    margin: 0.75em 0 0;
  }

  ::ng-deep .kg-gallery-image:not(:first-of-type) {
    margin: 0 0 0 0.75em;
  }

  ::ng-deep .kg-gallery-card {
    padding: 20px 0;
  }

  ::ng-deep .kg-gallery-card + .kg-image-card.kg-width-wide,
  ::ng-deep .kg-gallery-card + .kg-gallery-card,
  ::ng-deep .kg-image-card.kg-width-wide + .kg-gallery-card,
  ::ng-deep .kg-image-card.kg-width-wide + .kg-image-card.kg-width-wide {
    margin: -2.25em 0 3em;
  }

  ::ng-deep iframe {
    display: block;
    margin: 0 auto;

    @media (max-width: 800px) {
      max-width: 100%;
    }
  }
}

@mixin home-news-fix {
  .news-section {
    .news-slider {
      .news-entry {
        .entry-container {
          flex-direction: column;
          /* stylelint-disable-next-line declaration-no-important */
          flex-wrap: nowrap !important;

          .entry-details {
            flex: 0 0 auto;

            a {
              display: inline-block;
            }
          }

          nt-lazy-picture {
            display: flex;
            flex: 1 1 100%;
          }
        }
      }
    }
  }
}
