/* stylelint-disable selector-max-compound-selectors */

/*
  Gradients
 */
@use 'sass:math';
@use 'sass:list';

.gradient-header {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 450px;
  overflow: hidden;

  .gradient-container {
    position: absolute;
    width: 100%;
    height: auto;
    min-height: 450px;
  }

  &.photo-background .gradient-container {
    background: url('../../assets/image/ruhrgebiet.jpg') no-repeat 70%/cover;
  }

  &.color-standard .gradient-container {
    background: linear-gradient(90deg, rgb(25, 156, 215) 0%, rgb(139, 234, 187) 100%);
  }

  &.color-e5 .gradient-container {
    background: linear-gradient(56.09deg, #8bb3c5 0%, #f49353 100%);
  }

  &.color-vr .gradient-container {
    background: linear-gradient(56.09deg, #8f8bc5 0%, #efa4d5 100%);
  }

  .grid-left {
    grid-template: list.slash(repeat(5, 250px), repeat(4, 250px));
  }

  .grid-right {
    grid-template: list.slash(repeat(5, 250px), repeat(6, 150px));
  }

  &.product-header-minimized {
    .gradient-container {
      min-height: 80px;
      max-height: 80px;
    }

    .gradient-fade-white {
      display: none;
    }
  }

  &.static-page-header {
    min-height: 200px;

    .gradient-container {
      position: relative;
      min-height: 200px;
      overflow: visible;
    }

    .gradient-fade-white {
      display: none;
    }

    header {
      position: relative;
      z-index: 2;
      margin-bottom: 50px;
      padding-top: 50px;
      padding-bottom: 35px;

      h1 {
        margin: 0;

        color: #fff;
        font-size: 30px;
        font-weight: 500;
        line-height: 35px;
        hyphens: auto;
        word-break: break-word;

        @media (max-width: 900px) {
          font-size: 25px;
        }
      }

      h2,
      p {
        margin: 0;

        color: #fff;
        font-size: 20px;
        font-weight: 300;
        line-height: 32px;
      }

      span {
        margin: 0;

        color: #fff;
      }
    }

    @media (max-width: 900px) {
      header {
        margin-bottom: 3vh;
      }
    }

    .header-content {
      flex-wrap: wrap;
      margin-top: -30px;
      padding-bottom: 100px;

      @media (max-width: 900px) {
        margin-top: 0;
        padding-top: 20px;
      }
    }
  }

  @media (max-width: 900px) {
    .grid-right,
    .grid-left {
      display: none;
    }
  }
}

.gradient-header.product-header {
  .header-content {
    left: calc(50% - 150px);
    max-width: 1150px;

    transform: translate(calc(-50% + 75px), -50%);

    .header-content-text {
      flex: 1 0 50%;
      order: 2;
      margin-left: 30px;

      text-align: right;

      h1 {
        margin-top: 80px;

        font-size: 40px;
        line-height: 46px;
      }

      .product-news-info {
        display: inline-block;
        padding: 10px;

        background-color: rgba(9, 69, 96, 0.2);
        border-radius: 17px;

        color: #fff;
        font-size: 14px;
        font-weight: bold;
        text-align: left;
      }
    }

    .header-content-image {
      flex: 0 0 auto;
      order: 1;

      img {
        position: unset;
      }
    }
  }
}

.gradient-fade-white {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 450px;

  background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

.header-content {
  display: flex;
  position: relative;
  top: 0;
  left: 0;

  .header-content-text {
    flex: 0 0 50%;

    h1 {
      margin-top: 50px;
      margin-bottom: 35px;
    }
  }

  .header-content-image {
    flex: 0 0 50%;

    text-align: center;

    img {
      position: relative;
      right: -10%;
      width: 100%;
      max-width: 500px;
    }
  }

  @media (max-width: 900px) {
    flex-wrap: wrap;

    .header-content-text {
      flex: 1 1 100%;
    }

    .header-content-image {
      flex: 1 1 100%;
      margin-bottom: 30px;

      img {
        right: 0;
      }
    }
  }
}

.grid-left {
  display: grid;
  position: absolute;
  bottom: 250px;
  left: 9%;
  height: 100%;

  transform: rotate(45deg);
}

.grid-right {
  display: grid;
  position: absolute;
  right: -150px;
  bottom: 280px;
  height: 100%;

  transform: rotate(45deg);
}

.grid-stripe {
  background: #fff;

  opacity: 0.2;

  &.header-stripe-left-1 {
    grid-area: 1 / 1 / 5 / 2;
  }

  &.header-stripe-left-2 {
    grid-area: 3 / 1 / 4 / 5;
  }

  &.header-stripe-right-1 {
    grid-area: 3 / 1 / 4/ 7;
  }

  &.header-stripe-right-2 {
    grid-area: 1 / 3 / 6 / 4;
  }

  &.header-stripe-right-3 {
    grid-area: 1 / 5 / 5 / 6;
  }
}
