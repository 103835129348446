/*
  Standard-Text
 */
h1 {
  color: #094560;
  font-size: 34px;
  font-weight: bold;
  line-height: 39px;
}

h2 {
  color: #094560;
  font-size: 28px;
  font-weight: 300;
  line-height: 38px;

  &.modified {
    font-size: 34px;
    line-height: 42px;
  }
}

h3 {
  color: #094560;
  font-size: 26px;
  font-weight: 300;
  line-height: 32px;
}

h4 {
  color: #094560;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}

p {
  color: #094560;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  text-align: left;
}

b {
  font-weight: 500;
}

a {
  color: #199cd7;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.modified-1 {
  color: #094560;
  font-size: 22px;
  font-weight: 300;
  line-height: 32px;
}

.modified-2 {
  color: #094560;
  font-size: 20px;
  font-weight: 300;
  line-height: 29px;
}

.modified-3 {
  color: #094560;
  font-size: 40px;
  font-weight: bold;
  line-height: 46px;
}

.modified-4 {
  color: #094560;
  font-size: 34px;
  font-weight: bold;
  line-height: 38px;
}
